import React from "react";
import emotion from "@emotion/styled";

const ContentView = emotion.div``;

const ContentInner = emotion.div`
  padding: 2rem;
  max-width: calc(4rem + 960px);
  margin-left: auto;
  margin-right: auto;
  font-size: 1.3rem;
  line-height: 1.85rem;
  height: 100%;
`;

export const Content = ({
  children,
  ...extra
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => (
  <ContentView {...extra}>
    <ContentInner>{children}</ContentInner>
  </ContentView>
);

const GrayContentView = emotion.div`
background-color: rgb(250, 250, 250);
box-shadow: 0px -10px 44px rgba(0, 0, 0, 0.05) inset;
border-top: solid 1px #e2e0e0;
border-bottom: solid 1px #e2e0e0;
`;

const GrayContentInner = emotion.div`
  padding: 2rem;
  max-width: calc(4rem + 960px);
  margin-left: auto;
  margin-right: auto;
  font-size: 1.3rem;
  line-height: 1.85rem;
  height: 100%;
`;

export const GrayContent = ({
  children,
  ...extra
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => (
  <GrayContentView {...extra}>
    <GrayContentInner>{children}</GrayContentInner>
  </GrayContentView>
);
