// @flow

import React, { ReactNode } from "react";
import emotion from "@emotion/styled";

const SectionView = emotion.section`
  margin-top: 2rem;
  margin-bottom: 6rem;
  letter-spacing: -0.063px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);

  h1 a {
    color: #333;
  }
`;

const SectionHeader = emotion.header`
  margin-bottom: 4rem;
  border-bottom: solid 3px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = emotion.h1``;

const SectionDescription = emotion.p`
  font-size: 1.3rem;
  font-weight: light;
`;

export const Section = ({
  title,
  description,
  children,
}: {
  title?: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
}) => (
  <SectionView>
    {(title || description) && (
      <SectionHeader>
        {title && <SectionTitle>{title}</SectionTitle>}
        {description && <SectionDescription>{description}</SectionDescription>}
      </SectionHeader>
    )}
    {children}
  </SectionView>
);
