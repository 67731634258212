import React from "react";
import emotion from "@emotion/styled";
import { CTAButton } from "./Buttons";

const apiURL = "https://api.codemix.com/v1/contact-form";

const Form = emotion.form`
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro", sans-serif;
`;

const Input = emotion.input`
  display: flex;
  flex: 1;
  flex-grow: 1;
  border: solid #c3c2e0 1px;
  font-size: 1.3rem;
  
  color: #040404;
  background-color: #f8fcff;
  padding: 0.5rem;
  box-shadow: 2px 2px 60px rgba(0, 0, 0, 0.2) inset;
  

  :focus {
    outline: dashed #8eb2c7 1px;
  }
`;

const TextArea = emotion.textarea`
  display: flex;
  flex: 1;
  height: 200px;
  font-size: 1.3rem;
  flex-grow: 1;
  border: solid #c3c2e0 1px;
  color: #040404;
  background-color: #f8fcff;
  padding: 0.5rem;
  box-shadow: 2px 2px 60px rgba(0, 0, 0, 0.2) inset;
  :focus {
    outline: dashed #8eb2c7 1px;
  }
`;

const Label = emotion.label`
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  > span {
    padding-top: 0.5rem;
    width: 200px;
    margin-right: 1rem;
  }

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const SubmitButton = emotion(CTAButton)`
  font-size: 2rem;
  padding: 1rem 2rem;

  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3);

  :focus {
    outline: dashed #8eb2c7 3px;
  }
`.withComponent("button") as any;

const FormButtons = emotion.div`
  flex: 1;
  margin-top: 2rem;
  text-align: right;
`;

const SubmittingIndicator = emotion.div`
  z-index: 999;
  color: #444;
  text-shadow: none;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: -1rem;
  right: -1rem;
  top: -1rem;
  bottom: -1rem;
`;

const ErrorIndicator = emotion.div`
  background: #fdfda6;
  text-shadow: none;

  color: #444;
  padding: 1rem;
  margin-bottom: 2rem;
`;

const SuccessIndicator = emotion.div`
  background: #eafde8;
  color: #111;
  text-shadow: none;
  padding: 1rem;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.8);
`;

const FormField = ({ title, children, ...extra }: any) => (
  <Label {...extra}>
    <span>{title}</span>
    {children}
  </Label>
);

export class ContactForm extends React.Component {
  state = {
    name: "",
    email: "",
    message: "",
    isSubmitting: false,
    hasError: false,
    hasSent: false,
  };

  handleChange = (e: any) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      hasError: false,
    });
  };

  handleSubmit = async (e: any) => {
    e.preventDefault();
    const { name, email, message, isSubmitting } = this.state;
    if (isSubmitting) {
      return;
    }
    this.setState({ ...this.state, isSubmitting: true });
    try {
      await fetch(apiURL, {
        method: "POST",
        body: JSON.stringify({ name, email, message }),
      });
      this.setState({
        ...this.state,
        message: "",
        isSubmitting: false,
        hasSent: true,
        hasError: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        ...this.state,
        isSubmitting: false,
        hasSent: false,
        hasError: true,
      });
    }
  };

  render() {
    const { name, email, message, isSubmitting, hasSent, hasError } =
      this.state;
    return (
      <Form onSubmit={this.handleSubmit} method="post" action={apiURL}>
        {isSubmitting && <SubmittingIndicator />}
        {hasError && (
          <ErrorIndicator>
            Something went wrong while sending your message, please try again,
            or send an email to{" "}
            <a href="mailto:hello@codemix.com">hello@codemix.com</a>
          </ErrorIndicator>
        )}
        {hasSent && (
          <SuccessIndicator>
            Thanks! We'll get back to you as soon as possible.
          </SuccessIndicator>
        )}

        <FormField title="Your Name">
          <Input
            autoFocus
            type="text"
            name="name"
            required
            value={name}
            onChange={this.handleChange}
          />
        </FormField>
        <FormField title="Email">
          <Input
            type="email"
            name="email"
            required
            value={email}
            onChange={this.handleChange}
          />
        </FormField>
        <FormField title="Message">
          <TextArea
            name="message"
            value={message}
            required
            onChange={this.handleChange}
          />
        </FormField>
        <FormButtons>
          <SubmitButton type="submit">
            {isSubmitting ? "Sending..." : hasSent ? "Send Another" : "Send"}
          </SubmitButton>
        </FormButtons>
      </Form>
    );
  }
}
