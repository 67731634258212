import React, { ReactNode } from "react";
import emotion from "@emotion/styled";

const LogoWallItem = emotion.div`
  padding: 2rem;
  flex-basis: ${({ isExpanded }: { isExpanded?: boolean }) =>
    isExpanded ? "100%" : "20%"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    transition: filter 0.2s linear;
    filter: grayscale(85%);
    width: 100%;
  }

  :hover img {
    filter: none;
  }

  @media only screen and (max-width: 480px) {
    flex-basis: ${({ isExpanded }) => (isExpanded ? "100%" : "50%")};
  }

  @media only screen and (min-width: 481px) and (max-width: 700px) {
    flex-basis: ${({ isExpanded }) => (isExpanded ? "100%" : "33%")};
  }
`;

const LogoWallView = emotion.div`
  display: flex;
  flex-wrap: wrap;
`;

export function PlainLogoWall({
  children,
  expandedIndex,
  ...props
}: {
  children: ReactNode;
  expandedIndex?: number;
}) {
  return (
    <LogoWallView {...props}>
      {React.Children.map(children, (child, index) => (
        <LogoWallItem
          isExpanded={index === expandedIndex}
          key={(child as any).key}
        >
          {child}
        </LogoWallItem>
      ))}
    </LogoWallView>
  );
}
