import emotion from "@emotion/styled";

export const Row = emotion.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Col = emotion.div`
  flex: ${(props: { flex?: number }) => props.flex || 1};
  margin-right: 1rem;
  margin-bottom: 1rem;
`;
