import React from "react";
import styled from "@emotion/styled";
import checkIcon from "../img/check2-icon.svg";

const CheckListView = styled.ul`
  margin: 2rem 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
`;

const CheckListItem = styled.li`
  min-height: 48px;
  display: flex;
  margin-bottom: 1rem;
`;

const Check = styled.img`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
`;

const CheckListItemContent = styled.div`
  flex: 1;
  h3 {
    color: #353e47;
    font-size: 2rem;
    line-height: 48px;
    margin-bottom: 0;
  }
`;

export function CheckList({
  children,
  ...extra
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLUListElement>,
  HTMLUListElement
>) {
  return (
    <CheckListView {...extra}>
      {React.Children.map(children, child => (
        <CheckListItem>
          <Check src={checkIcon} />
          <CheckListItemContent>{child}</CheckListItemContent>
        </CheckListItem>
      ))}
    </CheckListView>
  );
}
