import Link from "gatsby-link";
import emotion from "@emotion/styled";

export const LinkButton = emotion(Link)`
  display: inline-block;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  color: #f44336;
  border: solid 2px #f44336;
  text-decoration: none;
  transition: all linear 0.15s;
  font-size: 1rem;
  font-family: "Source Sans Pro", sans-serif;
  font-variant: small-caps;
  text-transform: uppercase;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }
`;

export const CTAButton = emotion(LinkButton)`
  color: white;
  border-bottom: solid 6px #b3463e;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  font-family: sans-serif;
  text-align: center;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif;
  font-variant: small-caps;
  background-color: #3077c6;
  border-color: #2862a4;
  color: #f4f7fb;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3);
  transition: all 0.2s linear;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  
  :hover {
    background-image: linear-gradient(to right, #2862a4, #3077c6);
  }
`;
