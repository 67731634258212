import emotion from "@emotion/styled";

export const MarkdownBlock = emotion.section`
  
  h1 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  h2 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  h3 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  p > code {
    font-size: 1rem;
  }

  .gatsby-highlight {
    margin-bottom: 2rem;
  }

  blockquote {
    color: #404048;
    margin: 0;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-left: #444 solid 1rem;
    padding: 1rem;
    transition: all 0.2s linear;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
`;
