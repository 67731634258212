// @flow
import React, { ReactNode } from "react";
import emotion from "@emotion/styled";

const JumbotronView = emotion.header`
  min-height: 400px;
  min-height: calc(100vh - 60px);
  color: #343d46;
  background-color: #f6f8fa;
  font-family: "Source Code Pro", monospace;
  position: static;
  display: flex;
  flex-direction: column;
  hr {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const JumbotronInner = emotion.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: calc(4rem + 960px);
  margin: auto;
  padding: 2rem;
  @media (max-width: 720px) {
    max-width: 100%;
  }
  @media (max-width: 960px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

const JumbotronContent = emotion.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const JumbotronImageView = emotion.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-basis: 300px;
  margin-left: 1rem;
  @media (max-width: 960px) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    flex-basis: 200px;
    justify-content: flex-start;
    img {
      height: auto;
      width: auto;
      max-height: 200px;
    }
  }
`;

export function Jumbotron({
  image,
  cta,
  children,
  ...extra
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  image?: ReactNode;
  cta?: ReactNode;
}) {
  return (
    <JumbotronView {...extra}>
      <JumbotronInner>
        <JumbotronContent>{children}</JumbotronContent>
        {image && <JumbotronImageView>{image}</JumbotronImageView>}
      </JumbotronInner>
      {cta}
    </JumbotronView>
  );
}
