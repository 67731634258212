import React from "react";
import {
  GoogleMap,
  Marker,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";

const WrappedMap = withScriptjs(
  withGoogleMap(({ children, ...extra }) => (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: 54.090266, lng: -1.230573 }}
    >
      <Marker position={{ lat: 54.090266, lng: -1.230573 }} />
    </GoogleMap>
  ))
);

export function OfficeMap() {
  return (
    <WrappedMap
      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDDa4AsYbWNENeaJQn9luw1L4ldKk1Dnj8"
      loadingElement={<div style={{ height: "100%" }} />}
      containerElement={<div style={{ height: 500 }} />}
      mapElement={<div style={{ height: "100%" }} />}
    />
  );
}
