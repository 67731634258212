import React from "react";
import emotion from "@emotion/styled";

const LogoWallItem = emotion.div`
  border: #e9e7e7 solid 1px;
  flex-basis: ${({ isExpanded }: { isExpanded?: boolean }) =>
    isExpanded ? "100%" : "20%"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
  box-shadow: 0 0 88px rgba(0, 0, 0, 0.05) inset;
  background: white;
  position: relative;

  :hover {
    background: white;
    box-shadow: 0 0 88px rgba(0, 0, 0, 0.05) inset, 5px 5px 30px rgba(0, 0, 0, 0.25);
    z-index: 2;
    transform: scale(1.3);
  }
  a {
    padding: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  > img {
      margin: 2rem;
      max-width: 80%;
      max-width: calc(100% - 4rem);
  }
  img {
    width: 100%;
  }
  @media only screen and (max-width: 480px) {
    flex-basis: ${({ isExpanded }) => (isExpanded ? "100%" : "50%")};
  }

  @media only screen and (min-width: 481px) and (max-width: 700px) {
    flex-basis: ${({ isExpanded }) => (isExpanded ? "100%" : "33%")};
  }
`;

const LogoWallView = emotion.div`
  display: flex;
  flex-wrap: wrap;
`;

export function LogoWall({
  children,
  expandedIndex,
  ...props
}: {
  children: React.ReactNode;
  expandedIndex?: number;
}) {
  return (
    <LogoWallView {...props}>
      {React.Children.map(children, (child, index) => (
        <LogoWallItem
          isExpanded={index === expandedIndex}
          key={(child as any).key}
        >
          {child}
        </LogoWallItem>
      ))}
    </LogoWallView>
  );
}
