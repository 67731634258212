import * as React from "react";
import { Link } from "gatsby";
import emotion from "@emotion/styled";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";

const PageView = emotion.section`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const Page = emotion.div`
  display: flex;
  transition: all 0.2s linear;
  flex-direction: column;
  background: #f6f8fa;
  width: 100%;
  height: 100%;
`;

const Main = emotion.main`
  margin-bottom: auto;
  min-height: calc(100vh - 300px);
`;

declare const __PATH_PREFIX__: string;

export const Layout = ({
  location,
  title,
  children,
}: React.PropsWithChildren<{ location: Location; title: React.ReactNode }>) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  let header;

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    );
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    );
  }
  return (
    <PageView data-is-root-path={isRootPath}>
      <Page>
        <Navbar />
        <Main>{children}</Main>
        <Footer />
      </Page>
    </PageView>
  );
};
