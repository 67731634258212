// @flow

import React from "react";
import emotion from "@emotion/styled";
import Link from "gatsby-link";
import { GreenCommentText } from "./Text";
import { CTAButton } from "./Buttons";
import { Col, Row } from "./Grid";
import twitterIcon from "../img/twitter-dark-icon.svg";
import githubIcon from "../img/github-dark-icon.svg";

const FooterView = emotion.footer`
  min-height: 400px;
  margin: 0;
  background-color: #343d46;
  color: #f6f8fa;
  padding-top: 1rem;
`;

const FooterContent = emotion.div`
  margin: auto;
  font-family: "Source Sans Pro", sans-serif;
  max-width: calc(4rem + 960px);
  padding: 2rem;
  font-size: 1.3rem;

  h1 {
    color: #f7f8fa;
  }
`;

const ButtonCol = emotion(Col)`
  text-align: right;
  padding-top: 0.5rem;
  @media (max-width: 960px) {
    text-align: center;
  }
`;

export const Footer = ({
  children,
  ...extra
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <FooterView {...extra}>
    <FooterContent>
      <Link to="/">
        <h1>codemix</h1>
      </Link>
      <GreenCommentText>
        the TypeScript experts.
        <br />
        email <a href="mailto:hello@codemix.com">hello@codemix.com</a>, or call
        us on <a href="tel:+441347833119">(+44) 01347 833 119</a>
        <br />
      </GreenCommentText>
      {children}
      <br />
      <Row>
        <Col>
          <a href="https://github.com/codemix">
            <img src={githubIcon} style={{ width: 140, marginRight: 40 }} />
          </a>{" "}
          <a href="https://twitter.com/codemixers">
            <img src={twitterIcon} />
          </a>
        </Col>
        <ButtonCol>
          <CTAButton to="/contact">Send Us A Message</CTAButton>
        </ButtonCol>
      </Row>
    </FooterContent>
  </FooterView>
);
