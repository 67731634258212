import emotion from "@emotion/styled";

const greenBackground = `'data:image/svg+xml;utf8,${encodeURIComponent(`
<svg xmlns="http://www.w3.org/2000/svg"
width="36" height="28" viewBox="0 0 36 28">

<text x="0" y="18" font-family="Source Code Pro, monospace" font-size="18" stroke="#c2ecba">
//
</text>
</svg>
`)}'`;

export const GreenCommentText = emotion.p`
  color: #c2ecba;
  text-shadow: none;
  font-size: 18px;
  line-height: 28px;
  background: transparent url(${greenBackground}) repeat-y;
  background-size: 36px 28px;
  background-position: top left;
  padding-left: 32px;
  font-family: "Source Code Pro", monospace;
  a {
    color: #c2ecba;
    font-weight: bold;
  }
`;

const background = `'data:image/svg+xml;utf8,${encodeURIComponent(`
<svg xmlns="http://www.w3.org/2000/svg"
width="36" height="28" viewBox="0 0 36 28">

<text x="0" y="18" font-family="Source Code Pro, monospace" font-size="18" stroke="#414a41" opacity="0.4">
//
</text>
</svg>
`)}'`;

export const CommentText = emotion.p`
  color: #414a41;
  text-shadow: none;
  font-size: 18px;
  line-height: 28px;
  background: transparent url(${background}) repeat-y;
  background-size: 36px 28px;
  background-position: top left;
  padding-left: 32px;
  font-family: "Source Code Pro", monospace;
  a {
    color: #414a41;
    font-weight: bold;
  }
`;
