import React from "react";
import emotion from "@emotion/styled";
import Link from "gatsby-link";

const MediaItemLink = emotion(Link)`
  transition: all 0.2s linear;
  display: flex;
  flex: 1;
  color: #444;
  padding: 1rem;
  align-items: center;
  border: solid 1px transparent;
  
  &:hover {
    background: rgba(255,255,255,0.9);
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(0, 0, 0, 0.1);
    
  }
  @media (max-width: 860px) {
    flex-direction: column;
    text-align: center;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }
`;

const MediaItemImage = emotion.div`
  flex: 1;
  text-align: center;
  margin-left: 1rem;
  margin-right: 3rem;

  @media (max-width: 860px) {
    margin-left: 0;
    margin-right: 1rem;
    max-width: 150px;
    img {
      margin-bottom: 0;
    }
  }
`;

const MediaItemInner = emotion.div`
  flex: 3;
  font-size: 1.3rem;
  line-height: 1.85rem;
  height: 100%;
  padding-right: 1rem;
  color: #444;
`;

export const MediaItem = ({ to, right, image, children, ...extra }: any) => (
  <MediaItemLink to={to} {...extra}>
    {right && <MediaItemInner>{children}</MediaItemInner>}
    {right && <MediaItemImage>{image}</MediaItemImage>}
    {!right && <MediaItemImage>{image}</MediaItemImage>}
    {!right && <MediaItemInner>{children}</MediaItemInner>}
  </MediaItemLink>
);
