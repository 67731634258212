import React from "react";
import emotion from "@emotion/styled";
import Link from "gatsby-link";

const NavbarView = emotion.nav`
  color: #343d46;
  background-color: #f6f8fa;
  position: fixed;
  height: 64px;
  width: 100%;
  display: flex;
  z-index: 50;
`;

const NavbarContainer = emotion.div`
  display: flex;
  flex: 1;
`;

const NavbarLink = emotion(Link)`
  outline: none;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 24px;
  color: #5a656f;
  padding-bottom: 2px;
  transition: all 0.1s linear;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-decoration-color: rgb(199, 234, 220);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;

  &.active {
    border-left: 1px solid #ebeff3;
    border-right: 1px solid #ebeff3;
    background: rgba(0, 0, 0, 0.03);
    
  }
`;

const NavbarMenu = emotion.div`
  display: flex;
  margin-right: -2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  @media (max-width: 960px) {
    display: none;
  }
`;

const NavbarBrand = emotion(NavbarLink)`
  margin-left: 1rem;
  padding-left: 0;
  margin-right: auto;
  font-weight: bold;
  padding-top: 2px;
`;

export const Navbar = ({
  children,
  onClick,
  ...extra
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <NavbarView {...extra}>
    <NavbarContainer>
      <NavbarBrand to="/">codemix</NavbarBrand>
      <NavbarMenu>
        <NavbarLink activeClassName="active" to="/contact">
          contact us
        </NavbarLink>
        {children}
      </NavbarMenu>
    </NavbarContainer>
  </NavbarView>
);
