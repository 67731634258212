import React from "react";
import emotion from "@emotion/styled";
import { CTAButton } from "./Buttons";

const CTABannerView = emotion.aside`
  border-top: #ddd solid 1px;
  border-bottom: #ddd solid 1px;
  padding: 2rem;
  color: #28323c;
  background: #eff2f6;
`;

const BigCTAButton = emotion(CTAButton)`
font-size: 1.6rem;
padding: 0.75rem 1.25rem;

`;

const CTABannerInner = emotion.div`
  max-width: 960px;
  text-shadow: 0 0 4px white;
  margin: auto;
  h2 {
    font-size: 1.8rem;
    font-weight: normal;
  }
`;

export function CTABanner({ cta, to, children, ...extra }: any) {
  return (
    <CTABannerView {...extra}>
      <CTABannerInner>
        {children}
        <BigCTAButton to={to}>{cta}</BigCTAButton>
      </CTABannerInner>
    </CTABannerView>
  );
}
